<template>
    <borish-contact :shown="preview && Object.keys(preview).length" :info="preview" :class="'error-404'"/>
</template>

<script>
    import store from "../store";
    import BorishContact from "../components/borishContact";

    export default {
        name: "NoteFoundPage",
        components: {BorishContact},
        computed: {
            preview() {
                return store.nf_page
            },
        },
    }
</script>

<style scoped>
</style>
