import { render, staticRenderFns } from "../templates/borish-contact.html?vue&type=template&id=cc5dd0e0&scoped=true&external"
import script from "./borishContact.vue?vue&type=script&lang=js"
export * from "./borishContact.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc5dd0e0",
  null
  
)

export default component.exports