<template>
    <div class="player-wrapper player-wrapper-404">
        <keep-alive>
            <video-player v-if="preview" :video="preview"
                          :class="'player-intro'"
                          :name="'404'" :player-pause="paused" :autoplay="!paused"
                          :loop="true" :controls="false"></video-player>
        </keep-alive>
    </div>
</template>

<script>
    import store from "../store";
    import VideoPlayer from "../components/videoPlayer";

    export default {
        name: "Watch",
        components: {VideoPlayer},
        props: {id: [Number, String], pause: Boolean},
        data() {
            return {
                videoId: this.id,
                loading: true,
                paused: this.pause,
            }
        },

        computed: {
            preview() {
                return store.nf_page
            },
        },

        watch: {
            pause(val) {
                this.paused = val
            },
        },

        activated() {
            this.paused = this.pause
        },

        deactivated() {
            this.paused = true
        },

        methods: {
            toggleHome() {
                this.$router.push({name: 'Home'})
            },

            openMenu(view) {
                this.$router.push({name: view})
            }
        }
    }
</script>

<style scoped>

</style>
