<template src="../templates/borish-contact.html"></template>

<script>
    export default {
        props: ['info', 'shown']
    }
</script>

<style scoped lang="scss">
</style>
